<template>
  <v-container>
    <br />
    <v-row align="center" justify="space-around">
      <v-btn depressed @click="previousClicked"> Previous </v-btn>
      <v-responsive max-width="156">
        <v-text-field
          dense
          flat
          rounded
          solo-inverted
          v-model="currentIndex"
          :hint="total"
          persistent-hint
          @keypress="goTo"
        ></v-text-field>
      </v-responsive>
      <v-btn depressed @click="nextClicked"> Next </v-btn>
    </v-row>
    <image-viewer
      v-bind:imageData="image"
      v-on:save="imageSaved"
      v-bind:loading="loading"
    />
  </v-container>
</template>

<script>
import ImageViewer from "@/components/ImageViewer.vue";
import axios from "axios";
import { Auth } from "aws-amplify";
export default {
  name: "Station",
  components: { ImageViewer },
  async beforeRouteUpdate(to, from, next) {
    this.openImage(0);
    next();
  },
  data: () => ({
    image: {},
    currentIndex: 1,
    loading: true,
    cuts: null,
    total: "",
  }),
  async created() {
    axios.get(`https://rates.seemarble.com/types/cuts.json`).then((res) => {
      this.cuts = res.data;
    });
  },
  mounted() {
    this.openImage(0);
    this._keyListener = function (e) {
      if (e.key === "ArrowRight" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.

        this.nextClicked();
      }
      if (e.key === "ArrowLeft" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.

        this.previousClicked();
      }
    };

    document.addEventListener("keydown", this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this._keyListener);
  },
  methods: {
    async imageSaved(data) {
      this.loading = true;
      let config = {
        headers: {
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
        },
      };

      axios
        .post(
          `https://kh5p39l30d.execute-api.us-east-1.amazonaws.com/default/image/${this.$route.params.id}/${data.index}`,
          data,
          config
        )
        .then((res) => {
          this.openImage(res.data.index / 1 + 1);
          this.loading = false;
        });
    },
    goTo: function (key) {
      if (key && key.charCode == 13) {
        this.openImage(this.currentIndex || 0);
      }
    },
    nextClicked: function () {
      this.openImage(this.currentIndex / 1 + 1);
    },
    previousClicked: function () {
      this.openImage(this.currentIndex / 1 - 1);
    },
    async openImage(index) {
      index = index / 1;
      if (index < 0) index = 0;

      this.currentIndex = index;
      this.loading = true;
      let config = {
        headers: {
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
        },
      };

      axios
        .get(
          `https://kh5p39l30d.execute-api.us-east-1.amazonaws.com/default/image/${this.$route.params.id}/${index}`,
          config
        )
        .then((res) => {
          let options = [];
          if (this.cuts && this.cuts["station" + this.$route.params.id]) {
            let types = this.cuts["station" + this.$route.params.id];
            types.station.forEach((t) => {
              let sp = {
                id: `1-${t.name}`,
                name: t.name,
                tag: "Station" + this.$route.params.id,
                subPrimals: [],
              };
              let pData = res.data.labels[t.name];
              options.push(sp);
              if (t.subPrimals) {
                t.subPrimals.forEach((s) => {
                  let count = 0;
                  if (pData) count = pData[s] || 0;
                  sp.subPrimals.push({ name: s, count });
                });
              }
            });

            types.common.forEach((t) => {
              let sp = {
                id: `2-${t.name}`,
                name: t.name,
                tag: "Other",
                subPrimals: [],
              };
              let pData = res.data.labels[t.name];
              options.push(sp);
              if (t.subPrimals) {
                t.subPrimals.forEach((s) => {
                  let count = 0;
                  if (pData) count = pData[s] || 0;
                  sp.subPrimals.push({ name: s, count });
                });
              }
            });
          }

          this.image = Object.assign(res.data, { options });
          this.currentIndex = this.image.index;
          this.total = `${this.image.index} of ${res.data.total}`;
          this.loading = false;
        });
    },
  },
};
</script>
