<template>
  <v-container>
    <amplify-authenticator>
      <div v-if="authState === 'signedin' && user">
        <div>Hello, {{user.username}}</div>
      </div>
      <amplify-sign-out></amplify-sign-out>
      <amplify-sign-in
            header-text="Login"
            slot="sign-in"
            hide-sign-up="true"
        ></amplify-sign-in>
    </amplify-authenticator>
  </v-container>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'AuthStateApp',
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;      
    })
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined
    }
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  }
}
</script>

<style>
amplify-authenticator {
  --container-align: baseline;
}
</style>