<template>
  <v-row>
    <v-col cols="6"
      ><v-container>
        <v-card :loading="loading" class="mx-auto my-12" max-width="720">
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img height="480" :src="imageData.url"></v-img>

          <v-card-title>{{ imageData.name }} </v-card-title>
          <v-card-actions>
            <v-btn color="primary" @click="save"> Save &amp; Next </v-btn>
          </v-card-actions>
          <v-card-text>
            <p align="right">
              {{ imageData.ts ? "saved on " + imageData.ts : "new" }}
            </p>
            <v-row
              v-for="val in selectedLabels"
              :key="val.id"
              align="center"
              class="mx-0"
            >
              <h3>{{ val.name }}</h3>
              <div
                class="grey--text ms-4"
                v-for="sp in val.subPrimals"
                :key="sp.key"
              >
                {{ sp.key }}: {{ sp.value }}
              </div>
              <br />
              <br />
            </v-row>
          </v-card-text>
          <v-alert
            border="left"
            color="gray"
            icon="mdi-keyboard"
            outlined
            type="success"
          >
            <b>Shortcuts</b>
            <ul>
              <li class="text-caption" ><code>Ctl + S</code> Save &amp; Next</li>
              <li class="text-caption" ><code>Ctl + Right</code> Next</li>
              <li class="text-caption" ><code>Ctl + Left</code> Previous</li>
              <li class="text-caption" ><code>Ctl + Num</code> Option Selection</li>
            </ul>
          </v-alert>
        </v-card> </v-container
    ></v-col>
    <v-col cols="6">
      <v-alert
        border="bottom"
        color="green"
        icon="mdi-food-steak"
        dense
        prominent
        text
        type="success"
        >Sub-Primals</v-alert
      >
      <div style="height: calc(100vh - 250px); overflow-y: scroll">
        <div v-for="t in imageData.options" :key="t.id">
          <v-card-title
            >{{ t.name
            }}<small class="grey--text ms-4">{{ t.tag }}</small></v-card-title
          >

          <v-card-text>
            <v-row
              v-for="s in t.subPrimals"
              :key="t.id + '::' + s.name"
              dense
              align="center"
              no-gutters
            >
              <v-col class="col-6">{{ s.name }} </v-col>
              <v-col class="col-6">
                <v-stepper v-model="s.count" :flat="true">
                  <v-stepper-header>
                    <template v-for="n in steps">
                      <v-stepper-step
                        :key="`${n}-step`"
                        :complete="s.count > n"
                        :step="n"
                        edit-icon="mdi-food-steak"
                        editable
                      >
                      </v-stepper-step>

                      <v-divider v-if="n !== steps.length" :key="n"></v-divider>
                    </template>
                  </v-stepper-header>
                </v-stepper>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ImageViewer",
  props: ["imageData", "cuts", "loading"],
  data: () => ({
    steps: [0, 1, 2, 3],
    e1: 0,
  }),
  mounted() {
    this._keyListener = function (e) {
      if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.

        this.save();
      }
      if (!isNaN(e.key / 1) && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.
        let num = e.key / 1;
        if (num > 0) {
          // 1let i = 1;
          for (let j = 0; j < this.imageData.options.length; j++) {
            let sp = this.imageData.options[j];
            if (sp.subPrimals.length >= num) {
              if (sp.subPrimals[num - 1].count == this.steps.length - 1)
                sp.subPrimals[num - 1].count = 0;
              else
                sp.subPrimals[num - 1].count =
                  (sp.subPrimals[num - 1].count || 0) + 1;
              return;
            } else {
              num = num - sp.subPrimals.length;
            }
          }
        }
      }
    };

    document.addEventListener("keydown", this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this._keyListener);
  },
  methods: {
    save: function () {
      let result = {
        name: this.imageData.name,
        index: this.imageData.index,
        url: this.imageData.url,
        labels: [],
      };
      this.imageData.options.forEach((p) => {
        let sp = p.subPrimals.filter((x) => x.count > 0);
        if (sp.length > 0) {
          result.labels.push({
            name: p.name,
            subPrimals: sp.map((x) => {
              let obj = {};
              obj.name = x.name;
              obj.value = x.count;
              return obj;
            }),
          });
        }
      });
      this.$emit("save", result);
    },
  },
  computed: {
    selectedLabels: function () {
      let labels = [];
      if (!this.imageData || !this.imageData.options) return [];
      this.imageData.options.forEach((p) => {
        let sp = p.subPrimals.filter((x) => x.count > 0);
        if (sp.length > 0) {
          labels.push({
            id: p.id,
            name: p.name,
            subPrimals: sp.map((x) => {
              let obj = {};
              obj.key = x.name;
              obj.value = x.count;
              return obj;
            }),
          });
        }
      });
      return labels;
    },
  },
};
</script>
